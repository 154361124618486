.gall {
  width: 110% !important;
  margin-left: -10vw !important;
  .h-gallery {
    width: 100%;
    // div#ReactGridGallery:first-child {
    //   margin-top: -238px !important;
    //   margin-left: 19vw !important;
    // }
    // .ReactGridGallery_tile:nth-child(3) {
    //   margin-top: -238px !important;
    //   margin-left: 42.5vw !important;
    // }
    // .ReactGridGallery_tile:nth-child(4) {
    //   right: -64vw !important;
    //   margin-top: -238px !important;
    // }
    // .ReactGridGallery_tile {
    //   padding: 0px;
    //   height: 238px;
    //   width: 297px;
    //   overflow: hidden;
    //   float: left;
    //   position: relative;
    //   margin-top: -60px;
    //   .ReactGridGallery_tile-viewport,
    //   img {
    //     height: 100% !important;
    //     width: 100% !important;
    //     img {
    //       margin-left: 0 !important;
    //       transition: all 0.5s ease;
    //     }
    //     :hover img {
    //       height: 252px !important;
    //       width: 315px !important;
    //       margin-left: -7px !important;
    //       margin-top: -6px !important;
    //     }
    //   }
    // }
  }
  .pic {
    height: 238px;
    width: 297px;
    overflow: hidden;
    float: left;
    position: relative;
    margin-left: 2vw !important;
    margin-top: -60px;
  }
  .pic-1 {
    margin-left: 19vw !important;
  }
  .pic-2 {
    margin-top: -238px !important;
    margin-left: 42.5vw !important;
  }
  .pic-3 {
    right: -64vw !important;
    margin-top: -238px !important;
  }

  .pic:hover {
    cursor: pointer;
    -webkit-box-shadow: 1px 5px 20px #6e6e6e;
    box-shadow: 1px 5px 20px #6e6e6e;
  }
  .pic img {
    height: 238px;
    width: 297px;
  }
  .grow img {
    height: 238px;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
  .grow img:hover {
    height: 252px;
    width: 315px;
    margin-left: -7px;
    margin-top: -6px;
  }

  .img {
    object-fit: contain;
    position: absolute;
  }

  .brush {
    position: absolute;
    object-fit: contain;
    z-index: -1;
    margin-top: -105px;
  }

  .drop {
    position: absolute;
    width: 100%;
    left: 0;
    .drop-19-2 {
      width: 31px;
      height: 95px;
      top: -75px;
      right: -11.2%;
      mix-blend-mode: multiply;
    }
    .drop-14 {
      width: 48px;
      height: 48px;
      top: 50px;
      right: -8%;
      mix-blend-mode: multiply;
    }
    .drop-14-copy {
      width: 46px;
      height: 46px;
      top: 785px;
      left: -7%;
      mix-blend-mode: multiply;
    }
    .drop-20 {
      width: 203px;
      height: 337px;
      mix-blend-mode: multiply;
      top: -10px;
      left: -14%;
    }
    .drop-21-2 {
      width: 100px;
      height: 503px;
      mix-blend-mode: multiply;
      left: -14%;
      top: 1430px;
    }
    .drop-22 {
      width: 80px;
      height: 81px;
      mix-blend-mode: multiply;
      left: -7%;
      top: 1391px;
    }
    .drop-12 {
      width: 13px;
      height: 14px;
      mix-blend-mode: multiply;
      left: -3%;
      top: 1370px;
    }
    .drop-23-2 {
      width: 141px;
      height: 503px;
      top: 630px;
      right: -11.2%;
      mix-blend-mode: multiply;
    }
    .drop-24-2 {
      width: 49px;
      height: 98px;
      top: 1055px;
      right: -11.2%;
      mix-blend-mode: multiply;
    }
    .drop-25 {
      width: 30px;
      height: 30px;
      top: 1690px;
      right: -3%;
      mix-blend-mode: multiply;
    }
  }

  .title {
    text-align: center;
    p {
      width: 760px;
      height: 82px;
      font-family: $font-roboto-light;
      font-size: 90px;
      font-weight: 300;
      line-height: 0.91;
      letter-spacing: -1.6px;
      text-align: center;
      color: $text-color;
      margin-top: 124px;
      margin-left: 16vw;
    }
    .brush-title {
      z-index: -1;
      width: 351px;
      mix-blend-mode: multiply;
      top: -200px;
      left: 34vw;
    }
  }

  .genre {
    font-family: $secondary-font-regular;
    font-size: 24px;
    line-height: 1.13;
    letter-spacing: -0.1px;
    text-align: left;
    color: $text-color;
    margin-bottom: 270px;
    margin-left: -1vw;
    width: 10%;
    p {
      position: relative;
      left: 3vw;
    }
  }

  .portrait {
    margin-top: 132px;
  }
  .img-landscape {
    margin-top: -27px;
  }

  .media {
    display: none;
  }

  .brush-5 {
    width: 417px;
    left: 13vw;
    margin-top: -150px;
    mix-blend-mode: multiply;
  }
  .brush-6 {
    width: 400px;
    left: 40vw;
    mix-blend-mode: multiply;
  }
  .brush-7 {
    width: 536px;
    left: 62vw;
    margin-top: -200px;
    mix-blend-mode: multiply;
  }
  .brush-8 {
    width: 447px;
    left: 15vw;
    margin-top: -90px;
    mix-blend-mode: multiply;
  }
  .brush-9 {
    width: 352px;
    height: 235px;
    left: 40vw;
    margin-top: -55px;
    mix-blend-mode: multiply;
  }
  .brush-10 {
    width: 491px;
    height: 383px;
    left: 63vw;
    margin-top: -110px;
    mix-blend-mode: multiply;
  }
  .brush-7-copy {
    width: 536px;
    left: 10.5vw;
    margin-top: -180px;
    mix-blend-mode: multiply;
  }
  .brush-6-copy {
    width: 410px;
    left: 40vw;
    margin-top: -85px;
    mix-blend-mode: multiply;
  }
  .brush-5-copy {
    left: 63vw;
    width: 417px;
    margin-top: -162px;
    mix-blend-mode: multiply;
  }
  .brush-5-copy-2 {
    left: 40vw;
    mix-blend-mode: multiply;
  }
  .brush-9-copy {
    width: 352px;
    height: 235px;
    left: 65vw;
    margin-top: -15px;
    mix-blend-mode: multiply;
  }
  .brush-10-copy {
    width: 491px;
    height: 383px;
    left: 15vw;
    margin-top: -100px;
    mix-blend-mode: multiply;
  }
  .brush-9-copy-2 {
    left: 41.5vw;
    margin-top: -40px;
    mix-blend-mode: multiply;
  }
  .brush-8-copy {
    left: 62.5vw;
    margin-top: -90px;
    mix-blend-mode: multiply;
  }
}
