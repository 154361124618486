.msg-success {
  margin-top: 128px;
  margin-left: 0;
  width: 100%;
  height: 100%;
  margin-bottom: 32vh;
  font-size: 20px;
}

.orders {

  @media only screen and (max-width: 1100px) {
    .drop-18-2 {
      margin-left: -10% !important;
    }
  }
  @media only screen and (max-width: 890px) and (min-width: 750px) {
    .drop-18-2 {
      margin-left: -10% !important;

    }
  }
  @media only screen and (max-width: 750px) and (min-width: 700px) {
    .drop-18-2 {
      margin-left: -10% !important;
    }
  }

  @media only screen and (max-width: 325px) {
    .drop-18-2  {
      margin-left: -10% !important;
    }
  }


  background-color: $body-bg;
  // margin-left: -8vw;
  max-width: 130%;
  .drop {
    position: absolute;
    width: 100%;
    left: 0;
    .media {
      display: none;
    }
    .drop-5 {
      height: 503px;
      width: 181px;
      object-fit: contain;
      position: absolute;
      right: -11%;
      width: 10%;
      top: -7vh;
    }
    .drop-10 {
      width: 95px;
      height: 95px;
      object-fit: contain;
      position: absolute;
      right: 10px;
      top: 365px;
      width: 20%;
      mix-blend-mode: multiply;
    }
    .drop-15 {
      width: 23px;
      height: 23px;
      object-fit: contain;
      position: absolute;
      right: 200px;
      top: 342px;
      mix-blend-mode: multiply;
    }
    .drop-18-2 {
      width: 49px;
      height: 81px;
      object-fit: contain;
      position: absolute;
      top: 800px;
      left: -13%;
      margin-left:-10%;
      mix-blend-mode: multiply;
    }
    .drop-10-bottom {
      width: 46px;
      height: 46px;
      object-fit: contain;
      position: absolute;
      top: 637px;
      left: -130px;
      mix-blend-mode: multiply;
    }
    .drop-4 {
      width: 80px;
      height: 81px;
      object-fit: contain;
      position: absolute;
      right: -10px;
      top: 1900px;
      mix-blend-mode: multiply;
    }
    .drop-11 {
      width: 41px;
      height: 41px;
      object-fit: contain;
      position: absolute;
      right: -50px;
      top: 2000px;
      mix-blend-mode: multiply;
    }
  }

  .image {
    .image-1 {
      width: 480px;
      max-width: 32%;
      object-fit: contain;
      position: relative;
      mix-blend-mode: multiply;
      left: 58%;
      top: 1%;
  	}
  }
  hr {
    position: absolute;
  }
  .sofa {
    .sofa-1 {
      width: 780px;
      max-width: 45%;
      object-fit: contain;
      position: relative;
      mix-blend-mode: multiply;
      left: 52%;
      top: 5vw;
    }
    hr.line-under-sofa {
      width: 755px;
      height: 2px;
      max-width: 45%;
      object-fit: contain;
      background-color: $color-line-under-sofa;
      border: none;
      position: relative;
      margin-left: 52%;
      top: 5vw;
    }
    span.text-size-sofa {
      width: 70px;
      height: 31px;
      font-family: $secondary-font-regular;
      font-size: 18px;
      line-height: 1.72;
      text-align: left;
      color: $secondary-color-price;
      position: relative;
      left: 72%;
      top: 4vw;
    }
  }

  #prices {
    text-align: center;
    .brush-1 {
      height: 361px;
      object-fit: contain;
      mix-blend-mode: multiply;
      position: absolute;
      right: 40%;
      top: -2vw;
    }

    span.title {
      font-family: $font-roboto-light;
      font-size: 90px;
      font-weight: 300;
      line-height: 3;
      letter-spacing: -1.6px;
      color: $primary-color-price;
      position: relative;
      z-index: 2;
    }
  }

  .best-price-guarantee {
    width: 320px;
    height: 110px;
    font-family: $font-roboto-regular;
    font-size: 50px;
    line-height: 1.2;
    text-align: left;
    color: $primary-color-price;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .remember-if-you-find {
    width: 516px;
    height: 300px;
    font-family: $secondary-font-regular;
    font-size: 24px;
    line-height: 1.67;
    text-align: left;
    color: $primary-color-price;
    margin-top: 10px;
    margin-bottom: 170px;
  }

  .which-size-would-you {
    width: 465px;
    height: 40px;
    font-family: $font-roboto-regular;
    font-size: 34px;
    line-height: 1.18;
    text-align: left;
    color: $primary-color-price;
    margin-top: -21vw;;
    margin-bottom: 10px;
  }

  .field-size {
    margin-top: 24px;
    margin-bottom: 40px;
    label {
      width: 200px;
      height: 40px;
      font-family: $secondary-font-regular;
      font-size: 24px;
      line-height: 1.67;
      text-align: left;
      color: $primary-color-price;
    }

    select {
      width: 320px;
      font-family: $secondary-font-regular;
      font-size: 24px;
      line-height: 1.67;
      text-align: left;
      background-color: $body-bg;
      border: none;
      margin-left: -40px;
    }

    hr.line-select-size {
      width: 480px;
      height: 2px;
      background-color: $secondary-color-price;
      border: none;
      margin-left: 0;
      margin-top: -3px;
      margin-bottom: 55px;
    }
  }

  .please-contact {
    width: 470px;
    height: 31px;
    font-family: $secondary-font-regular;
    font-size: 18px;
    line-height: 1.72;
    text-align: left;
    color: $secondary-color-price;
    margin-top: -35px;
    margin-bottom: 60px;
  }

  .transport-fees {
    width: 512px;
    height: 140px;
    font-family: $font-roboto-regular;
    font-size: 34px;
    line-height: 1.18;
    text-align: left;
    color: $primary-color-price;
    margin-bottom: -50px;
  }

  .the-transport-fees {
    width: 516px;
    height: 176px;
    font-family: $secondary-font-regular;
    font-size: 24px;
    line-height: 1.67;
    text-align: left;
    color: $primary-color-price;
    margin-bottom: 30px;
    margin-top: -13vw;
  }

  .field-transport {
    margin-top: 24px;
    margin-bottom: 40px;
    label {
      width: 114px;
      height: 40px;
      font-family: $secondary-font-regular;
      font-size: 24px;
      line-height: 1.67;
      text-align: left;
      color: $primary-color-price;
    }

    select {
      width: 366px;
      height: 40px;
      font-family: $secondary-font-regular;
      font-size: 24px;
      line-height: 1.67;
      text-align: left;
      color: $secondary-color-price;
      background-color: $body-bg;
      border: none;
      margin-left: 0px;
    }

    hr.line-select-transport {
      width: 360px;
      height: 2px;
      background-color: $secondary-color-price;
      border: none;
      margin-left: 120px;
      margin-top: -8px;
      margin-bottom: 20px;
    }
  }

  .field-comment {
    margin-top: 24px;
    margin-bottom: 40px;

    #order_comment {
      width: 480px;
      height: 45px;
      font-family: $secondary-font-regular;
      font-size: 24px;
      line-height: 1.67;
      text-align: left;
      color: $secondary-color-price;
      background-color: $body-bg;
      border: none;
      margin-left: 0px;
    }

    hr.line-comment {
      width: 480px;
      height: 2px;
      background-color: $secondary-color-price;
      border: none;
      margin-left: 0px;
      margin-top: -8px;
      margin-bottom: 20px;
    }
  }

  .we-estimate {
    width: 469px;
    height: 40px;
    font-family: $secondary-font-regular;
    font-size: 24px;
    line-height: 1.67;
    text-align: left;
    color: $primary-color-price;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .chf {
    width: 100%;
    height: 60px;
    font-family: $font-roboto-regular;
    font-size: 50px;
    line-height: 1.2;
    text-align: left;
    color: $primary-color-price;
  }

  .click-below {
    width: 400px;
    height: 31px;
    font-family: $secondary-font-regular;
    font-size: 18px;
    line-height: 1.72;
    text-align: left;
    color: $secondary-color-price;
  }



  div.file {
    margin-left: 310px;
    margin-top: 80px;
    .outtaHere{
      display:none;
    }

    label[for="upload"] {
      display: inline-block;
      position: absolute;
      cursor:pointer;
      padding: 20px 0;
      line-height: 0.9;
      background-color: $secondary-color-price;
      text-align: center;
      font-size: 22px;
      color: $body-bg;
      width: 170px;
      height: 60px;
      &:hover {
        color: lighten($body-bg, 10);
        box-shadow: $shadow-6;
      }
    }
  }


  .field-upload-picture {
    margin-top: -69px;
    label {
      overflow: hidden;
      width: 302px;
      height: 32px;
      font-family: $secondary-font-regular;
      font-size: 24px;
      line-height: 1.45;
      text-align: left;
      color: $secondary-color-price;
      margin-top: 88px;
    }

    hr.line-under-upload {
      width: 300px;
      height: 2px;
      background-color: $secondary-color-price;
      border: none;
      margin-left: 0px;
      margin-top: -2px;
      margin-bottom: 20px;
    }
  }
  .stars {
    color: red;
    font-size: 12px;
    position: relative;
    left: -12px;
    top: 70px;
  }
  .field-info {
    margin-top: 51px;
    margin-bottom: 8px;

    .order {
      width: 480px;
      height: 45px;
      font-family: $secondary-font-regular;
      font-size: 24px;
      line-height: 1.67;
      text-align: left;
      color: $secondary-color-price;
      background-color: $body-bg;
      border: none;
    }
  }

  .line-info {
    width: 480px;
    height: 2px;
    background-color: $secondary-color-price;
    border: none;
    margin-top: -8px;
    margin-bottom: 0px;
    margin-left: 0px;
  }

  #error_explanation {
    font-family: $secondary-font-regular;
    color: red;
    margin-top: 20px;
    margin-bottom: -65px;
    h3 {
      width: 480px;
    }
    li {
      list-style-type: none;
      margin-left: -20px;
      padding-left: -20px;
    }
  }

  .actions {
    margin-top: 100px;
    input {
      width: 340px;
      height: 60px;
      border: none;
      font-family: $secondary-font-regular;
      font-size: 24px;
      line-height: 1.13;
      letter-spacing: -0.1px;
      text-align: center;
      background-color: $btn-action-color;
      color: $body-bg;
      margin-bottom: 260px;
      margin-top: 80px;

      &::before {
        content: '';
        position: absolute;
        background: darken($send-btn-color, 40%);
        border: 50vh solid darken($send-btn-color, 20%);
        width: 30vh;
        height: 30vh;
        border-radius: 50%;
        display: block;
        top: 50%;
        left: 50%;
        z-index: 0;
        opacity: 1;
        transform: translate(-50%, -50%) scale(0);
      }

      &:hover {
        color: lighten($body-bg, 10);
        box-shadow: $shadow-6;
      }

      &:active,
      &:focus {
        &::before {
          transition: transform ($mf-transition-speed * 4) ease, opacity $mf-transition-speed ease ($mf-transition-speed * 1.3);
          transform: translate(-50%, -50%) scale(1);
          opacity: 0;
        }
      }

      &:focus {
        outline: none;
      }
    }
  }

}
