// Place all the styles related to the TermsAndCondition controller here.
// They will automatically be included in application.css.
// You can use Sass (SCSS) here: http://sass-lang.com/

.terms {
  position: relative;
  .drops-body-1 {
    position: absolute;
    width: 100%;
    height: 100%;

    .drop-7 {
      position: absolute;
      left: 8vw;
      top: 35%;
      max-width: 23px;
      width: 2vw;
      z-index: 0;
    }
    .drop-2 {
      position: absolute;
      left: 0;
      top: 50%;
      max-width: 103px;
      width: 7vw;
      z-index: 0;
    }
    .drop-10 {
      position: absolute;
      z-index: 0;
      right: 15vw;
      top: 65%;
      max-width: 74px;
      width: 5vw;
    }
    .drop-5 {
      position: absolute;
      z-index: 0;
      right: 0;
      top: 85%;
      max-width: 361px;
      width: 22vw;
    }
    .media {
      display: none;
    }
  }

  .drops-body-2 {
    position: absolute;
    width: 100%;
    height: 100%;
    .media {
      display: none;
    }
    .drop-4 {
      position: absolute;
      right: 15vw;
      top: 8%;
      max-width: 81px;
      width: 6vw;
      z-index: 0;
    }
    .drop-3 {
      position: absolute;
      z-index: 0;
      right: 21vw;
      top: 10%;
      max-width: 14px;
      width: 1vw;
      z-index: 0;
    }
    .drop-media-8 {
      position: absolute;
      bottom: 15%;
      left: 0;
      max-width: 113px;
      width: 7vw;
      z-index: 0;
    }
  }
  .lg-bottom {
    position: absolute !important;
    left: 3%;
    bottom: 300px !important;
    z-index: 2;
  }

  .title {
    margin-top: 200px;
    margin-bottom: 300px;

    .title-text{
      max-width: 1332px;
      font-family: $font-roboto-light;
      font-weight: 300;
      font-size: 97px;
      line-height: 1;
      letter-spacing: -1.7px;
      color: $primary-color-price;
      z-index: 2;
      position: relative;
      margin-left: auto;
      margin-right: auto;
      word-wrap: break-word;
    }
    .brush-1 {
      position: absolute;
      top: 80px;
      left: 30%;
      width: 525px;
      max-width: 525px;
      transform: rotate(-70deg);
    }
  }

  .part {
    padding-left: 5vw;
    margin-bottom: 100px;
    z-index: 2;

    .title {
      display: inline-block;
      margin-top: 0px;
      margin-bottom: 48px;
      font-family: $font-regular;
      font-size: 50px;
      line-height: 1.64;
      letter-spacing: -0.9px;
      color: $text-color;
    }

    .body{
      font-family: $font-regular;
      font-size: 19px;
      line-height: 1.79;
      color: $text-color;
    }
  }

}
