footer {
  background-color: $footer-color;

  .container-fluid {
    background-color: $footer-color !important;
  }
  #logo {
    width: 100%;
    height: 75px;
    float: left;
    object-fit: contain;
  }

  .footer-copyright {
    color: #fff;
  }
  .contact {
    color: #fff;
    clear: both;
    padding-top: 17px;
    text-align: left;
  }

  #nav-menu {
    padding: 0;
    float: right;
    margin-top: 23px;
    font-family: $secondary-font-regular;
    font-size: 24px;
    font-weight: 200;
    line-height: 1.13;
    letter-spacing: -0.1px;
    list-style-type: none;
    li {
      margin-bottom: 25px;
    }
    a {
      &:link {
        text-decoration: none;
        color: $white;
      }
      &:visited {
        text-decoration: none;
        color: $white;
      }
      &.active {
        position: relative;
      }
      &.active::after {
        left: 50%;
        margin-left: -1px;
        top: -10px;
        position: absolute;
        display: block;
        width: 6px;
        height: 6px;
        content: "";
        background-color: $bullit-color;
        border-radius: $round-radius;
      }
      &:hover {
        color: $hover-color-a;
      }
    }
  }
  #social {
    text-align: right;
    ul {
      list-style-type: none;
      display: inline-flex;
      padding: 0;
      li {
        font-size: 24px;
        padding: 15px;
        i {
          color: white;
        }
      }
    }
  }
}


