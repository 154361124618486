@media only screen and (max-width: 1100px) {
  #footer {
    padding-right: 100px;
    #nav-menu, #social {
      padding-left: 0px;
    }
  }
}
@media only screen and (max-width: 890px) and (min-width: 750px) {
  #footer {
    padding-left: 55px;
    padding-right: 45px;
    #nav-menu, #social {
      padding-left: 0px;
      font-size: 18px;
      margin-top: 30px;
    }
  }
}
@media only screen and (max-width: 750px) and (min-width: 700px) {
  #footer {
    padding-right: 4px !important;
    height: 115px !important;
  }
  #nav-menu, #social {
    position: relative !important;
    margin-left: 0 !important;
    top: 27px;
    margin-top: 0 !important;
  }
}
@media only screen and (max-width: 768px) {
  // .row{
  //   display: contents !important;
  // }
  footer {
    #social{
      text-align: center;
    } 
    .contact{
      text-align: center;
    }
    #nav-menu, #social {
      width: 100%;
      
    }
  }
}

@media only screen and (max-width: 325px) {
  #footer #nav-menu a,   #footer #social a {
    font-size: 14px;
    font-weight: 200;
  }
}
