@media only screen and (max-width: 1130px) and (min-width: 621px) {
  #about_body #content {
    #title {
      margin-top: -76px !important;
    }
    .brush-title {
      width: 387px !important;
      left: 22vw !important;
      top: -90px !important;
    }
    #img .brush-2 {
      top: -25vw !important;
    }
    .about-title p {
      width: 58vw !important;
    }
  }
}
@media only screen and (max-width: 899px) and (min-width: 621px) {
  #about_body #content {
    #title {
      font-size: 75px !important;
      margin-top: -90px !important;
    }
    .brush-title {
      width: 325px !important;
      left: 20vw !important;
    }
    .top-pic{
      position: relative !important;
      top: -53px !important;
    }
    #img .brush-2 {

    }
    .about-title p {
      font-size: 20px;
    }
  }
}
@media only screen and (max-width: 620px) {
  #about {
    #about_header .about-header-drops .no-media {
      display: none;
    }
    #about_body {
      #content {
        #title {
          width: 210px;
          height: 50px;
          font-size: 48px;
          font-weight: 300;
          line-height: 1.04;
          letter-spacing: -0.8px;
          top: -120px;
          left: 2vw;
        }
        .brush-title {
          width: 213.3px;
          height: 140px;
          left: 11vw;
          top: -50px;
        }
        #img {
          .brush-2 {
            position: absolute;
            display: block;
            top: -86vw;
            left: -10vw;
            height: auto;
            width: 90vw;
          }

          .artist-on-work {
            display: none;
          }
          .artist-on-work-media {
            display: inline-block;
            width: 280px;
            position: relative;
            top: -300px;
            left: -2vw;
          }
          .img-drops .no-media {
            display: none;
          }
        }
        .about-body-drops .no-media {
          display: none;
        }
        .about-body-drops .drop-11 {
          width: 28px;
          height: 29px;
          display: inline-block;
          mix-blend-mode: multiply;
          position: absolute;
          bottom: -50px;
          right: 10vw;
        }
        #description {
          margin-left: 2vw;
          margin-top: -250px;
          font-size: 16px;
          line-height: 1.69;
          max-width: 100%;
          margin-bottom: 150px;
          .about-title p {
            width: 66vw;
          }
          p.header {
            width: 75px;
            height: 32px;
            font-size: 30px;
            line-height: 1.07;
          }
          .about-info {
            font-size: 20px;
          }
        }
      }
    }
  }

}
@media only screen and (max-width: 600px) {
  #about #about_body #content {
    #title {
      left: 0;
    }
    .brush-title {
      left: 3vw;
    }
    #img .artist-on-work-media {
      left: -11vw;
    }
  }
  p.about-title {
    width: 210px;
  }
}
@media only screen and (max-width: 600px) and (min-width: 410px) {
  #about #about_body #content {
    .brush-title {
      left: 12vw !important;
      z-index: -2;
    }
    #img .artist-on-work-media {
      left: 2vw !important;
    }
  }
}
@media only screen and (max-width: 409px) and (min-width: 359px) {
  #about #about_body #content {
    .brush-title {
      left: 8vw !important;
    }
    #img .artist-on-work-media {
      left: -3vw !important;
    }
  }
}
