$language-picker-color: #ff7200;
$round-radius: 50%;
$font-regular: 'HKGroteskRegular';
$font-light: 'HKGroteskLight';
$font-medium: 'HKGroteskMedium';
$secondary-font-regular: 'Montserrat', sans-serif;
$black: #000;
$bullit-color: #62c8a5;
$white: #fff;
$header-color: #191e2b;
$text-color: #191e2b;
$footer-color: #171212;
$modal-back-color: rgba(25, 30, 43, 0.8);
$send-btn-color: #62c8a5;
$primary-color: #ff7200;
$secondary-color: $send-btn-color;
$cancel-color: #ef3c14;
$button-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.33);
$lg-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.33);
$secondary-color-price: #8e9199;
$primary-color-price: $header-color;
$color-line-under-sofa: #d7e0ea;
$btn-action-color: #3eb890;
$font-roboto-light: 'Roboto Slab', serif;
$font-roboto-regular: 'Roboto Slab', serif;
$font-pt-serif: 'PT Serif', serif;
$hover-upload-color: #d7e0ea;
$hover-color-a: #ff8800;
$slogan-color-media: #bec2cc;
$font-awesome: 'FontAwesome';

$body-bg: $white;
// Shadows
$shadow-inset: inset 0 2px 2px 0 rgba(0,0,0,.14);
$shadow-0: 0 0 1px rgba(0, 0, 0, 0);
$shadow-2: 0 2px 2px 0 rgba(0,0,0,.14),0 3px 1px -2px rgba(0,0,0,.2),0 1px 5px 0 rgba(0,0,0,.12);
$shadow-3: 0 3px 4px 0 rgba(0,0,0,.14),0 3px 3px -2px rgba(0,0,0,.2),0 1px 8px 0 rgba(0,0,0,.12);
$shadow-4: 0 4px 5px 0 rgba(0,0,0,.14),0 1px 10px 0 rgba(0,0,0,.12),0 2px 4px -1px rgba(0,0,0,.2);
$shadow-6: 0 6px 10px 0 rgba(0,0,0,.14),0 1px 18px 0 rgba(0,0,0,.12),0 3px 5px -1px rgba(0,0,0,.2);
$shadow-8: 0 8px 10px 1px rgba(0,0,0,.14),0 3px 14px 2px rgba(0,0,0,.12),0 5px 5px -3px rgba(0,0,0,.2);

// Font Sizes
$mf-font-size: 16px !default;
$mf-active-font-size: $mf-font-size * 0.8 !default;
$mf-active-top: -$mf-font-size !default;

// Margin & Paddings
$mf-gap: $mf-font-size * 1.5 !default;
$mf-spacer: $mf-font-size / 8 !default;
$mf-fieldset-margin: 0 0 $mf-gap * 2 !default;
$mf-checkbox-gap: $mf-font-size * 2 !default;
$mf-checkbox-top: 0 !default;
$mf-radio-margin: $mf-font-size / -4 !default;

// Colors
$mf-input-color: #333 !default;
$mf-border-color: #999 !default;
$mf-label-color: lighten($mf-border-color, 10%) !default;
$mf-active-color: darken($mf-border-color, 10%) !default;
$mf-focus-color: $primary-color !default;
$mf-error-color: #d9534f !default;

// Animation
$mf-transition-speed: 0.28s !default;
