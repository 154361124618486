.pl0 {
  padding-left: 0 !important;
}

.pr0 {
  padding-right: 0 !important;
}

.pt0 {
  padding-top: 0 !important;
}

.pb0 {
  padding-bottom: 0 !important;
}

.ml0 {
  margin-left: 0 !important;
}

.mr0 {
  margin-right: 0 !important;
}

.mt0 {
  margin-top: 0 !important;
}

.mb0 {
  margin-bottom: 0 !important;
}

.p0 {
  padding: 0 !important;
}

.m0 {
  margin: 0 !important;
}

.mr6p {
  margin-right: 6%;
}

.back-white {
  background-color: $white;
  z-index: -2;
}
