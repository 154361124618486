input,
select,
textarea{
  font-family: $secondary-font-regular;
  box-sizing: border-box;

  &::after,
  &::before {
    box-sizing: border-box;
  }
}

body {
  background-image: linear-gradient(top, darken($body-bg, 5%), darken($body-bg, 10%));
}

h1 {
  font-size: 2rem;
  text-align: center;
  margin: 0 0 2em;
}

.container {
  position: relative;
  max-width: 40rem;
  margin: 5rem auto;
  background: $body-bg;
  width: 100%;
  padding: 3rem 5rem 0;
  border-radius: 1px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: $shadow-8;
    transform: scale(0.98);
    transition: transform $mf-transition-speed ease-in-out;
    z-index: -1;
  }

  &:hover::before {
    transform: scale(1);
  }
}

.button-container {
  text-align: center;
}

// The Styles for the form
fieldset {
  margin: $mf-fieldset-margin;
  padding: 0;
  border: none;
}

.form-radio,
.form-group {
  position: relative;
  margin-top: ($mf-gap * 1.5);
  margin-bottom: ($mf-gap * 1.5);
}

.form-inline {
  > .form-group,
  > .btn {
    display: inline-block;
    margin-bottom: 0;
  }
}

.form-help {
  margin-top: $mf-spacer;
  margin-left: $mf-spacer;
  color: $mf-label-color;
  font-size: $mf-active-font-size;

  .checkbox &,
  .form-radio &,
  .form-group & {
    position: absolute;
    width: 100%;
  }

  .checkbox & {
    position: relative;
    margin-bottom: $mf-font-size;
  }

  .form-radio & {
    padding-top: ($mf-spacer * 2);
    margin-top: -$mf-font-size;
  }
}

.form-group {
  // scss-lint:disable QualifyingElement, NestingDepth
  input {
    height: ($mf-font-size * 1.9);
  }

  textarea {
    resize: none;
  }

  select {
    width: 100%;
    font-size: $mf-font-size;
    height: ($mf-font-size * 1.6);
    padding: $mf-spacer $mf-spacer ($mf-spacer / 2);
    background: none;
    border: none;
    line-height: 1.6;
    box-shadow: none;
  }

  .control-label {
    position: absolute;
    top: ($mf-font-size / 4);
    pointer-events: none;
    padding-left: $mf-spacer;
    z-index: 1;
    color: $mf-label-color;
    font-size: $mf-font-size;
    font-weight: normal;
    transition: all $mf-transition-speed ease;
  }

  .bar {
    position: relative;
    border-bottom: ($mf-font-size / 16) solid $mf-border-color;
    display: block;

    &::before {
      content: '';
      height: ($mf-font-size / 8);
      width: 0;
      left: 50%;
      bottom: ($mf-font-size / -16);
      position: absolute;
      background: $mf-focus-color;
      transition: left $mf-transition-speed ease, width $mf-transition-speed ease;
      z-index: 2;
    }
  }

  input,
  textarea {
    display: block;
    background: none;
    padding: $mf-spacer $mf-spacer ($mf-spacer / 2);
    font-size: $mf-font-size;
    border-width: 0;
    border-color: transparent;
    line-height: 1.9;
    width: 100%;
    color: transparent;
    transition: all $mf-transition-speed ease;
    box-shadow: none;
  }

  input[type="file"] {
    line-height: 1;

    ~ .bar {
      display: none;
    }
  }

  select,
  input:focus,
  input:valid,
  input.form-file,
  input.has-value,
  textarea:focus,
  textarea:valid,
  textarea.form-file,
  textarea.has-value {
    color: $mf-input-color;

    ~ .control-label {
      font-size: $mf-active-font-size;
      color: $mf-active-color;
      top: $mf-active-top;
      left: 0;
    }
  }

  select,
  input,
  textarea {
    &:focus {
      outline: none;

      ~ .control-label {
        color: $mf-focus-color;
      }

      ~ .bar {
        &::before {
          width: 100%;
          left: 0;
        }
      }
    }
  }
}

// Radio & Checkbox
.checkbox,
.form-radio {
  label {
    position: relative;
    cursor: pointer;
    padding-left: $mf-checkbox-gap;
    text-align: left;
    color: $mf-input-color;
    display: block;
  }

  input {
    width: auto;
    opacity: 0.00000001;
    position: absolute;
    left: 0;
  }
}

.radio {
  margin-bottom: $mf-font-size;

  .helper {
    position: absolute;
    top: $mf-radio-margin;
    left: $mf-radio-margin;
    cursor: pointer;
    display: block;
    font-size: $mf-font-size;
    user-select: none;
    color: $mf-border-color;

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      margin: ($mf-spacer * 2);
      width: $mf-font-size;
      height: $mf-font-size;
      transition: transform $mf-transition-speed ease;
      border-radius: 50%;
      border: ($mf-font-size / 8) solid currentColor;
    }

    &::after {
      transform: scale(0);
      background-color: $mf-focus-color;
      border-color: $mf-focus-color;
    }
  }

  label:hover .helper {
    color: $mf-focus-color;
  }

  // scss-lint:disable QualifyingElement, NestingDepth
  input:checked {
    ~ .helper {
      &::after {
          transform: scale(0.5);
      }
      &::before {
        color: $mf-focus-color;
      }
    }
  }
}

.checkbox {
  // scss-lint:disable QualifyingElement

  margin-top: ($mf-gap * 2);
  margin-bottom: $mf-font-size;

  .helper {
    color: $mf-border-color;
    position: absolute;
    top: $mf-checkbox-top;
    left: 0;
    width: $mf-font-size;
    height: $mf-font-size;
    z-index: 0;
    border: ($mf-font-size / 8) solid currentColor;
    border-radius: ($mf-font-size / 16);
    transition: border-color $mf-transition-speed ease;

    &::before,
    &::after {
      position: absolute;
      height: 0;
      width: ($mf-font-size * 0.2);
      background-color: $mf-focus-color;
      display: block;
      transform-origin: left top;
      border-radius: ($mf-font-size / 4);
      content: '';
      transition: opacity $mf-transition-speed ease, height 0s linear $mf-transition-speed;
      opacity: 0;
    }

    &::before {
      top: ($mf-font-size * 0.65);
      left: ($mf-font-size * 0.38);
      transform: rotate(-135deg);
      box-shadow: 0 0 0 ($mf-font-size / 16) $body-bg;
    }

    &::after {
      top: ($mf-font-size * 0.3);
      left: 0;
      transform: rotate(-45deg);
    }
  }

  label:hover .helper {
    color: $mf-focus-color;
  }

  input:checked {
    ~ .helper {
      color: $mf-focus-color;

      &::after,
      &::before {
        opacity: 1;
        transition: height $mf-transition-speed ease;
      }

      &::after {
        height: ($mf-font-size / 2);
      }

      &::before {
        height: ($mf-font-size * 1.2);
        transition-delay: $mf-transition-speed;
      }
    }
  }
}

.radio + .radio,
.checkbox + .checkbox {
  margin-top: $mf-font-size;
}

.has-error {
  .legend.legend,
  &.form-group .control-label.control-label {
    // Prevent !importantRule
    color: $mf-error-color;
  }

  &.form-group,
  &.checkbox,
  &.radio,
  &.form-radio {
    .form-help,
    .helper {
      color: $mf-error-color;
    }
  }

  .bar {
    &::before {
      background: $mf-error-color;
      left: 0;
      width: 100%;
    }
  }
}

.button {
  position: relative;
  background: currentColor;
  border: 1px solid currentColor;
  font-size: ($mf-font-size * 1.1);
  color: $send-btn-color;
  margin:( $mf-gap * 2) 0;
  padding: ($mf-gap / 2) ($mf-gap * 2);
  cursor: pointer;
  transition: background-color $mf-transition-speed ease, color $mf-transition-speed ease, box-shadow $mf-transition-speed ease;
  overflow: hidden;
  box-shadow: $shadow-2;

  span {
    font-family: $font-regular;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.13;
    letter-spacing: -0.1px;
    text-align: left;
    color: $white;
    position: relative;
    z-index: 1;
  }

  &::before {
    content: '';
    position: absolute;
    background: darken($send-btn-color, 40%);
    border: 50vh solid darken($send-btn-color, 20%);
    width: 30vh;
    height: 30vh;
    border-radius: 50%;
    display: block;
    top: 50%;
    left: 50%;
    z-index: 0;
    opacity: 1;
    transform: translate(-50%, -50%) scale(0);
  }

  &:hover {
    color: lighten($send-btn-color, 10);
    box-shadow: $shadow-6;
  }

  &:active,
  &:focus {
    &::before {
      transition: transform ($mf-transition-speed * 4) ease, opacity $mf-transition-speed ease ($mf-transition-speed * 1.3);
      transform: translate(-50%, -50%) scale(1);
      opacity: 0;
    }
  }

  &:focus {
    outline: none;
  }
}
