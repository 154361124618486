@media only screen and (max-width: 1024px) {
  #header {
    padding-top: 29px;
    min-height: 168px;
    .logo {
      top: -19px;
      img {
        width: 161px;
      }
      span {
        margin-top: -22px;
        font-size: 18px;
        line-height: 2.22;
        width: 210px;
        color: $slogan-color-media;
      }
    }
    #lg-group {
      top: 113px;
    }
    .nav-menu {
      display: none;
    }
    .mobile-menu {
      display: inline-block;
      left: 80vw;
      top: 18px;;
      #btn-menu + label{
        display: inline-block;
       }
      #btn-menu + label + .list-menu{
        display: none;
      }
      #btn-menu:checked + label + .list-menu{
        display: block;
      }
    }
  }
}
@media only screen and (max-width: 1024px) and (min-width: 900px) {
  #header .mobile-menu {
    left: 82vw;
    * {
      font-size: 16px;
    }
  }
}
@media only screen and (max-width: 615px) {
  #header {
    .logo span {
      width: 162px;
    }
    .mobile-menu {
      left: 66vw;
    }
  }
}
@media only screen and (max-width: 325px) {
  #header {
    .mobile-menu {
      top: 29px;
      left: 71vw;
      font-size: 0.9rem;
      nav {
        padding: 0px;
      }
      .list-menu {
        padding-left: 5px;
        font-size: 14px;
        margin-top: 10px;
        margin-left: -33px !important;
      }
      #btn-menu + label {
        left: 53px;
      }
    }
  }
}
@media only screen and (max-width: 360px) {
  #header {
    .mobile-menu {
      .list-menu {
        width: 117px;
        margin-left: -25px;
      }
    }
  }
}
